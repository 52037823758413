
















import {Component, Vue} from 'vue-property-decorator'

@Component({
  metaInfo() { return { title: 'À propos' } }
})
export default class About extends Vue {}
